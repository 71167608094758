import React, { useEffect, useState } from "react";

import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import banner2 from '../Assets/Container.svg'
function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
   <>
    <Navbar/>
    {/* <div className='pd-banner'></div> */}
        <br/>
        <br/>
    
        <h1 className='check-heading' style={{textAlign:'center'}}>Contact Us</h1>
        <br/>
        <br/>
    <div className='Contact'>
    <div className='Contact-left'>
    <img src={banner2}/>

</div>
    <div className='Contact-right'>
    <div className='Contact-form'>
    <h2>Get In Touch</h2>

    <br/>
    <br/>
{/* <label>Name</label>
<input placeholder='Emter Name' />
<label>Name</label>
<input placeholder='Emter Name' />
<label>Name</label>
<input placeholder='Emter Name' />
<label>Name</label>
<input placeholder='Emter Name' />
<br/>
<button>Send</button> */}
<div className='cont-icon-div'>
  <div>
  <div className='cont-icon'>
    <i class="bi bi-geo-alt-fill"></i>
    </div>
  </div>
    <h3>The Juices, 100 feet road, Madhapur,<br/> Hyderabad, 500081.</h3>
</div>
<br/>
<br/>

<div className='cont-icon-div'>
 <div>
 <div className='cont-icon'>
    <i class="bi bi-telephone-fill"></i>
    </div>
 </div>
    <h3>+91 8977037763</h3>
</div>
<br/>
<br/>


<div className='cont-icon-div'>
   <div>
   <div className='cont-icon'>
    <i class="bi bi-envelope-fill"></i>
    </div>
   </div>
    <h3>thejuices.co@gmail.comm</h3>
</div>

</div>

</div>

    </div>
    <Footer/>
   </>
  )
}

export default Contact