import React, { useEffect, useState } from "react";

import Navbar from '../Components/Navbar'
import Products from '../Components/Products'
import Footer from '../Components/Footer'

function OurProducts() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
        <Navbar/>
        <div className='aboutbanner'>
    <div className='aboutbannercolor'>
    <h1>OUR PRODUCTS</h1>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus,<br/> luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
    </div>

   

    </div>
  
    <div className='products-div'>
    <p>Featured Product </p>
        <h1>Healthy living was done with fruit juice.</h1>

        </div>
    <Products/>

    <Footer />
    </>
  )
}

export default OurProducts