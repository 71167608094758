import React, { useEffect, useState } from "react";
import "./login.css"; // Replace with the path to your CSS file
import { baseUrl } from "../utils/data"; // Ensure this is the correct path
import { toast } from "react-toastify";
import axios from "axios";
import Navbar from "../Components/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonLoader from "../loader/ButtonLoader";

const LoginSignupCard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      navigate("/");
    }
  }, []);

  const handleSendOtp = async () => {
    if (mobile.length != 10) {
      return toast.error("Mobile Number should be 10 Digit.");
    }
    setLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/auth/login`, { mobile });
      if (response.status === 200) {
        setOtpSent(true);
        toast.success("OTP sent successfully");
      }
    } catch (error) {
      toast.error("Failed to send OTP");
    }
    setLoading(false);
  };

  const handleVerifyOtp = async () => {
    if (otp.length != 4) {
      return toast.error("OTP should be 4 Digit.");
    }
    setLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/auth/verify`, {
        mobile,
        otp,
      });
      if (response.status === 200) {
        toast.success("Login successful");
        localStorage.setItem(
          "userData",
          JSON.stringify(response?.data?.data?.user)
        );
        localStorage.setItem("token", response?.data?.data?.token);
        const { name, mobile } = response?.data?.data?.user;
        if (!name || !mobile) {
          toast.error("Please Update Your Profile First.");
          navigate("/Profile");
          return;
        }else{
          navigate(-1);
        }
      }
    } catch (error) {
      toast.error("Invalid OTP");
    }
    setLoading(false);
  };

  const handleReset = () => {
    setMobile("");
    setOtp("");
    setOtpSent(false);
  };

  if (JSON.parse(localStorage.getItem("userData"))) {
    return null;
  }

  return (
    <>
      <Navbar />
      <div>
        <div className="center-card">
          <h2>Login or Signup</h2>
          <br />
          <br />

          <div className="name-inps">
            <input
              type="text"
              placeholder="Mobile Number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
            {otpSent && (
              <input
                type="text"
                placeholder="OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            )}
          </div>
          <br />

          {!otpSent ? (
            <button onClick={handleSendOtp} disabled={loading}>
              Send OTP {loading && <ButtonLoader />}
            </button>
          ) : (
            <div style={{ display: "flex", gap: "15px" }}>
              <button onClick={handleVerifyOtp} disabled={loading}>
                Submit {loading && <ButtonLoader />}
              </button>
              <button onClick={handleReset} disabled={loading}>
                Reset
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LoginSignupCard;
