import React, { useEffect, useState } from "react";

import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { Link } from "react-router-dom";

function Plans() {
   useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <>
           <Navbar/>
           <div className='aboutbanner aboutbanner2'>
    <div className='aboutbannercolor'>
    <h1>Choose Your <span>Perfect Plan</span> </h1>
<p>At The Juices, our mission is to make healthier choices easy and accessible for everyone. We've chosen to offer our products exclusively through a subscription<br/> model for these compelling reasons</p>
    </div>

   

    </div>
    <div className="plan-points">
    <ul>
      <li>Consistency in Nutrition and Hydration Intake</li>
      <li>Convenience and Peace of Mind</li>
      <li>Personalized Experience
</li>
      <li>Sustainability and Waste Reduction 
</li>
      <li>Exclusive Benefits
</li>
      <li>Quality Assurance</li>
      <li>Building a Community
</li>


    </ul>
    <br/>
    <br/>
    <h3>By choosing the subscription model, The Juices is dedicated to providing a seamless, convenient, and personalized experience that supports our customers in their journey towards a better lifestyle. We believe that this approach not only benefits our customers but also helps us achieve our vision of making healthier choices easy and accessible for everyone.
</h3>

    </div>

    

        
<div style={{backgroundColor:'rgba(255, 248, 238, 1)'}} className='plans'>
<div className='plan-cards'>
<div className='plan-card'>
<div className='plan-heading'>
<h2>Trial pack </h2>
</div>

<br/>

<div className='plan-text'>
<h3>Starts From Rs.600/subscription</h3>
<br/>

<br/>
<div className='plan-text-lines'>
   <p><i class="bi bi-caret-right-fill"></i> 6 products
   </p>
</div>
<br/>
<div className='plan-text-lines'>
   <p><i class="bi bi-caret-right-fill"></i> Convert to full Subscription 
</p>
</div>
<br/>

<div className='plan-text-lines'>
   <p><i class="bi bi-caret-right-fill"></i> Only Once per product per user 
</p>

</div>
<br/>

<div className='plan-text-lines'>
   <p><i class="bi bi-caret-right-fill"></i> Pause at convenience
</p>

</div>
<br/>
<br/>
<Link to='/newproduct'>

<button>Get Now</button>
</Link>
</div>

</div>
<div className='plan-card'>
<div className='plan-heading'>
<h2>
Month Subscription
</h2>
</div>

<br/>


<div className='plan-text'>

<h3>Starts From 2,300/subscription</h3>
<br/>
<br/>


<div className='plan-text-lines'>
   <p><i class="bi bi-caret-right-fill"></i> 26 products
</p>
</div>
<br/>
<div className='plan-text-lines'>
   <p><i class="bi bi-caret-right-fill"></i> Renew at special price
</p>
</div>
<br/>
<div className='plan-text-lines'>
   <p><i class="bi bi-caret-right-fill"></i> Family packages available
</p>
</div>
<br/>
<div className='plan-text-lines'>
   <p><i class="bi bi-caret-right-fill"></i> Pause at convenience 

</p>
</div>
<br/>
<br/>
<Link to='/newproduct'>

<button>Get Now</button>
</Link>
</div>

</div>

</div>
</div>

<Footer/>
    </>
  )
}

export default Plans
