// SmallScreenLoader.js
import React from 'react';
import './SmallScreenLoader.css';

const SmallScreenLoader = () => {
    return (
        <div className="small-screen-loader">
            <div className="spinner"></div>
        </div>
    );
};

export default SmallScreenLoader;
