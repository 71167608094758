import React, { useEffect } from "react";

import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
function ShippingPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Navbar/>
    <div className='Terms'>
<h1>Delivery <span>Policy</span></h1>
<br/>
<p>At THE JUICES, we strive to provide our customers with a seamless and hassle-free
experience. To ensure that your products are delivered in a timely and efficient manner, we have
outlined our delivery policy as follows</p>
    </div>
    <br/>
    <div className='Terms2'>

<br/>


<p>1. Delivery will be done only on the available locations that our company operates in, also which
might change whenever our company expands or limits itself from delivering to that location. Once order is conformed it will be shipped within 24 hrs.</p>


<br/>
<p>2. Delivery is free on purchases.</p>
<br/>


<p>3. Our prices are all inclusive of taxes</p>
<br/>


<p>4. Once you buy a product, you are buying a subscription which means you will be delivered
everyday until your subscription ends, for which you choose the start date and pause whenever
it is not required in our website or letting us know via call before 24 hours of delivery slot.
</p>
<br/>


<p>5. Please note that you product will be delivered everyday until the subscription ends only on
the specified slot that is provided by you to us during the purchase of the product, changes in
this should be notified 24 hours before the product is out for delivery by messaging in WhatsApp
or giving a call.
</p>
<br/>


<p>6. Delivery of all products will be duly done to the Delivery address as mentioned by you at the
time of placing the order. In case of any modification, kindly let us know by messaging in
WhatsApp or giving a call.
</p>
<br/>


<p>7. Once your product is out for delivered, you will receive a SMS and a notification in our
application and website.</p>


    </div>
    <Footer/>
   </>
  )
}

export default ShippingPolicy
