import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../utils/data";
import { toast } from "react-toastify";
import AddressDialog from "./AddressDialog";
import { Button } from "@mui/material";
import Navbar from "./Navbar";
import Footer from "./Footer";
import img from "../Assets/image 196.svg";

const initialStage = {
  productId: "",
  startDate: "",
  deliveryAddress: "",
  slot: "",
  packageType: "",
  deliveryLocation: {
    lat: 0,
    lng: 0,
  },
  orderAmount: 0,
  packingAmount: 0,
};

const TrialPackDialog = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [product, setProduct] = useState(initialStage);
  const [addresses, setAddresses] = useState([]);
  const [addressDialogOpen, setAddressDialogOpen] = useState(false);
  // console.log(product);

  const fetchAddresses = async () => {
    try {
      const response = await axios.get(`${baseUrl}/user/bytoken`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setAddresses(response?.data?.data?.deliveryAddress);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  useEffect(() => {
    if (location.state) {
      const { product, startDate, slot, packageType } = location.state;
      setProduct((prev) => ({
        ...prev,
        ...product,
        productId: product?._id,
        startDate,
        slot,
        packageType,
        packageCharge: 0,
        orderAmount: product.trialPack,
      }));
    }
    fetchAddresses();
  }, [location.state]);

  const handleTrialSubscription = async () => {
    const userData = JSON.parse(localStorage.getItem("userData")) || null;
    if (userData) {
      const { name, mobile } = userData;
      if (!name || !mobile) {
        toast.error("Please Update Your Profile First.");
        navigate("/Profile");
        return;
      } else {
        const {
          productId,
          startDate,
          deliveryAddress,
          slot,
          packageType,
          deliveryLocation,
          orderAmount,
          packingAmount,
        } = product;
        if (!productId) return toast.error("Please Refersh Page");
        if (!startDate) return toast.error("Start Date not found.");
        if (!deliveryAddress) return toast.error("Delivery Address not found.");
        if (!slot) return toast.error("Please Select Slot.");
        if (!packageType) return toast.error("Please Select Package Type");
        if (
          !deliveryLocation ||
          !deliveryLocation.lat ||
          !deliveryLocation.lng
        ) {
          return toast.error("Please Select Location On Map");
        }
        if (!orderAmount) return toast.error("Product Amount Not found.");
        try {
          const res = await axios.post(
            `${baseUrl}/subscription/trial`,
            {
              productId,
              startDate,
              deliveryAddress,
              slot,
              packageType,
              deliveryLocation,
              orderAmount,
              packingAmount: packingAmount || 0,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (res?.status == 200) {
            // toast.success("Subscription Buy Successfully.");
            // console.log(res);
            const orderId = res?.data?.data?.batchId;
            if (!orderId) {
              return toast.error("Please Try Again.");
            }
            const result = await axios.post(
              `${baseUrl}/phonepe/phoneperequest`,
              {
                amount: orderAmount,
                merchantUserId: orderId,
              }
            );
            if (result?.status == 200) {
              window.location.href = result?.data;
            }
            // navigate("/");
          }
        } catch (error) {
          console.log(error?.response?.data?.message);
          if (error?.response?.data?.message == "Already used Trial.") {
            toast.error("Already used Trial.");
          } else {
            toast.error("Buy Subscription Failed.");
          }
        }
      }
    }
  };

  const handleAddressSelect = (selectedAddressId) => {
    const selectedAddress = addresses.find(
      (address) => address._id === selectedAddressId
    );
    if (selectedAddress) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        deliveryAddress: selectedAddress.address,
        deliveryLocation: selectedAddress.location,
      }));
    }
    setAddressDialogOpen(false);
  };

  console.log(product);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <div className="SingleProductDetail">
        <div className="SingleProductDetail-left">
          <div className="main-img">
            <img src={product?.imageUrl || img} />
          </div>
        </div>
        <div className="SingleProductDetail-right">
          <h2>Product Name: {product?.name}</h2>
          <p>
            Product Start Date:{" "}
            {new Date(product?.startDate).toLocaleDateString()}
          </p>
          <p>Slot: {product?.slot}</p>
          <p>
            Package:{" "}
            {product?.packageType == "onetimeuse"
              ? "Plastic Bottle"
              : "Glass Bottle"}
          </p>
          <p>Price : {product?.orderAmount}</p>
          <p>Package Charge : {product?.packingAmount}</p>
          <p>Delivery Address: {product.deliveryAddress}</p>
          <p>
            Location: {product.deliveryLocation.lat},{" "}
            {product.deliveryLocation.lng}
          </p>
          <Button onClick={() => setAddressDialogOpen(true)}>
            Select Address
          </Button>
          <br />
          <br />
          <button className="addtocart" onClick={handleTrialSubscription}>
            BUY Trial
          </button>

          <AddressDialog
            open={addressDialogOpen}
            handleClose={() => setAddressDialogOpen(false)}
            handleSelectAddress={handleAddressSelect}
            fetchAddresses={fetchAddresses}
            addresses={addresses}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TrialPackDialog;
