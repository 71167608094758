import React, { useEffect, useState } from "react";
import img from "../Assets/newlogo.png";
import img2 from "../Assets/image 197.svg";
import fvticon from "../Assets/Vector (1).svg";
import icon from "../Assets/icon-delivery.svg";
import payment from "../Assets/Payment Method.svg";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../utils/data";
import axios from "axios";
import { toast } from "react-toastify";

function SingleSubProductDetail() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [mainImage, setMainImage] = useState(img);

  const handleAddtoCart = async () => {
    try {
      const res = await axios.post(
        `${baseUrl}/cart`,
        { productId: id, quantity: quantity },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res?.status === 200) {
        toast.success("Product Added in Cart.");
      }
    } catch (error) {
      if (error?.response?.data?.message === "User not authorized") {
        toast.error("Please Login Again.");
        navigate("/Login");
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}/subproduct/single/${id}`);
      if (res?.status === 200) {
        setProduct(res?.data?.data);
        if (res?.data?.data?.images?.image1) {
          setMainImage(res?.data?.data?.images?.image1);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleImageClick = (image) => {
    setMainImage(image);
  };

  const handleIncrement = () => setQuantity(quantity + 1);
  const handleDecrement = () => setQuantity(quantity > 1 ? quantity - 1 : 1);

  return (
    <>
      <div className="SingleProductDetail">
        <div className="SingleProductDetail-left">
          <div className="main-img">
            <img src={mainImage} alt="Main product" />
          </div>
          <div className="side-img">
            {product?.images?.image1 && (
              <div className="side-box" onClick={() => handleImageClick(product.images.image1)}>
                <img src={product.images.image1 || img2} style={{ width: "75px" }} alt="Product thumbnail" />
              </div>
            )}
            {product?.images?.image2 && (
              <div className="side-box" onClick={() => handleImageClick(product.images.image2)}>
                <img src={product.images.image2 || img2} style={{ width: "75px" }} alt="Product thumbnail" />
              </div>
            )}
            {product?.images?.image3 && (
              <div className="side-box" onClick={() => handleImageClick(product.images.image3)}>
                <img src={product.images.image3 || img2} style={{ width: "75px" }} alt="Product thumbnail" />
              </div>
            )}
            {product?.images?.image4 && (
              <div className="side-box" onClick={() => handleImageClick(product.images.image4)}>
                <img src={product.images.image4 || img2} style={{ width: "75px" }} alt="Product thumbnail" />
              </div>
            )}
            {product?.images?.image5 && (
              <div className="side-box" onClick={() => handleImageClick(product.images.image5)}>
                <img src={product.images.image5 || img2} style={{ width: "75px" }} alt="Product thumbnail" />
              </div>
            )}
          </div>
        </div>
        <div className="SingleProductDetail-right">
          <h1>{product?.name}</h1>
          <p>{product?.description}</p>
          <br />
          <button className="addtocart" onClick={() => navigate(`/ProductDetails/${product?.product?._id}`)}>View Main Product</button>
          <br />
          <br />
        </div>
      </div>
    </>
  );
}

export default SingleSubProductDetail;
