import React, { useEffect } from "react";

import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function ReturnnRefund() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Navbar/>
    <div className='Terms'>
<h1>Cancellation  <span>& Refund Policy</span></h1>
<br/>
<p>At THE JUICES, we strive to provide our customers with a seamless and hassle-free shopping
experience. To ensure that you are completely satisfied with your purchase, we have outlined
our cancellation and refund policy as follows</p>
    </div>
    <br/>
    <div className='Terms2'>
<h2>Subscription Cancellation:</h2>
<br/>


<p>If you wish to cancel your SUBSCRIPTION, please do so within 24 hours of next product
delivery. You can cancel your subscription by emailing us at support@thejuices.co or by
messaging us on WhatsApp or giving us a call to the number mentioned in the website. We will
not be able to process cancellations after this period.
</p>
<br/>
<h2>Return Policy:</h2>
<br/>
<p>1.  If product is damaged we take return and exchange of product after 1 hrs of the delivery.</p>
<br/>

<p>2. In the unlikely event that you receive a damaged or defective product, you need to share a
video at support@thejuices.co to be eligible for extending the subscription as a compensation
for the damaged product.</p>
<br/>
<p>3. Please note that there will be no compensation provided if the product is out for delivery and
is not accepted by you under any circumstances without prior information of 24 hours.</p>
<br/>
<h2>Refund Policy:</h2>
<br/>
<p>1. In case or Refund request drop a mail at support@thejuices.co , if approved the refund will be initiated and getting credited in your bank account or initial mode of payment within 7 - 10 working days.</p>
<br/>

<p>2. We offer a refund on all orders that are cancelled within 24 hours of next product delivery.
Please note that Refund amount for the remaining days of subscription will only be issued after
cancellation.
</p>
<br/>
<p>3. Refunds will be issued in your bank account, which you need to mention over email.</p>
<br/>
<p>4. Refunds will be processed within 7 working days from the date of cancellation of subscription.</p>

   
    </div>
    <Footer/>
   </>
  )
}

export default ReturnnRefund