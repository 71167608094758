import React, { useEffect, useState } from "react";


import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AllProduct from "./Dashboard/AllProduct";
import DeliveryProduct from "./Dashboard/DeliveryProduct";
import CalendarProduct from "./Dashboard/CalendarProduct";
import load from '../Assets/newlogo.png'
function Dashboard() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(true); // Add loading state

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (!token || !userData) {
      navigate("/Login");
      toast.error("Please Login First.");
    }
    setLoading(false); 
  }, [navigate]);



  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 86400000); // Update every 24 hours

    // Clean up the interval on component unmount
    return () => clearInterval(timer);
  }, []);

  const formattedDate = currentDate.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit'
  });
  if (loading) {
    return (
      <div className="loader-div">
      <img src={load} alt="Logo" class="loader-logo"/>
      <div class="spinner"></div>
      </div>
    );
  }
  return (
    <>
      <Navbar />
    
      <br />
      <br />

      <h1 className="check-heading" style={{ textAlign: "center" }}>
        Dashboard
      </h1>
      <br />
      <br />
      <div className="tab-container">
        <div>
          <div className="tabs-center">
            <div className="tabs tabs2">
              <button
                className={activeTab === 1 ? "tab-btn active" : "tab-btn"}
                onClick={() => handleTabChange(1)}
              >
               {formattedDate}
              </button>
              <button
                className={activeTab === 2 ? "tab-btn active" : "tab-btn"}
                onClick={() => handleTabChange(2)}
              >
                <i class="bi bi-calendar-check-fill"></i>
              </button>
              <button
                className={activeTab === 3 ? "tab-btn active" : "tab-btn"}
                onClick={() => handleTabChange(3)}
              >
              <i class="bi bi-pause-circle-fill"></i>
                <i class="bi bi-play-fill"></i>
              </button>
            </div>
          </div>
        </div>
        <br/>
        <br/>
        <br/>
        <div className="Dashboard-tab">
          {activeTab === 1 && <AllProduct />}
          {activeTab === 2 && <DeliveryProduct/>}
          {activeTab === 3 && <CalendarProduct/>}
        </div>
      </div>

     
      <Footer />
    </>
  );
}

export default Dashboard;
