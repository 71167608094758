import React, { useEffect, useState } from "react";
import img from "../Assets/image 196.svg";
import img2 from "../Assets/image 197.svg";
import fvticon from "../Assets/Vector (1).svg";
import icon from "../Assets/icon-delivery.svg";
import payment from "../Assets/Payment Method.svg";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../utils/data";
import axios from "axios";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useCart } from "../cartContext/cartContext";
import load from '../Assets/newlogo.png'
function SingleProductDetail() {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [slot, setSlot] = useState("");
  const [packageType, setPackageType] = useState("onetimeuse");
  const [startDate, setStartDate] = useState("");
  const [mainImage, setMainImage] = useState(img);
  const {setCartLength} = useCart()

  const filterPassedDates = (date) => {
    const day = date.getDay();
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return day !== 0 && date > today;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (product) {
      setMainImage(product.imageUrl || img);
    }
  }, [product]);

  const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleAddtoCart = async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (!userData) {
      toast.error("Please Login First.")
      navigate("/Login");
      return
    }
    if (!product) return toast.error("Product Not Found");
    const { price, packageCharge } = product;
    if (!price) return toast.error("Product Amount Missing.");
    if (!slot) return toast.error("Please Select Slot");
    if (!packageType) return toast.error("Please Select Package Type");
    if (!startDate) return toast.error("Please Select Start Date.");

    const payload = {
      productId: id,
      slot,
      packageType,
      startDate,
      orderAmount: price,
      packingAmount: 0,
    };
    if (packageType === "onetimeuse") {
      payload.packingAmount = packageCharge;
    }

    try {
      const res = await axios.post(`${baseUrl}/newcart/addtocart`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (res?.status === 200) {
        toast.success("Product Added to Cart.");
        setCartLength(res?.data?.data?.length)
      }
    } catch (error) {
      if (error?.response?.data?.message === "User not authorized") {
        toast.error("Please Login Again.");
        navigate("/Login");
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const handleSubscribe = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (!userData) {
      toast.error("Please Login First.")
      navigate("/Login");
      return
    }
    if (!slot || !packageType || !startDate) {
      return toast.error("Please Select All Data.");
    }
    if (!product) {
      return toast.error("Product Not Found.");
    }
    if (packageType !== "onetimeuse") {
      return toast.error("Package Type should be Plastic Bottle");
    } else {
      navigate("/trial-pack", {
        state: {
          product,
          slot,
          packageType,
          startDate,
        },
      });
    }
  };

  const handleBuyNow = async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (!userData) {
      toast.error("Please Login First.")
      navigate("/Login");
      return
    }
    if (!product) return toast.error("Product Not Found");
    const { price, packageCharge } = product;
    if (!price) return toast.error("Product Amount Missing.");
    if (!slot) return toast.error("Please Select Slot");
    if (!packageType) return toast.error("Please Select Package Type");
    if (!startDate) return toast.error("Please Select Start Date.");

    const payload = {
      productId: id,
      slot,
      packageType,
      startDate,
      orderAmount: price,
      packingAmount: 0,
    };
    if (packageType === "onetimeuse") {
      payload.packingAmount = packageCharge;
    }

    try {
      const res = await axios.post(`${baseUrl}/newcart/addtocart`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (res?.status === 200) {
        toast.success("Product Added to Cart.");
        setCartLength(res?.data?.data?.length)
        navigate("/CheckOut");
      }
    } catch (error) {
      if (error?.response?.data?.message === "User not authorized") {
        toast.error("Please Login Again.");
        navigate("/Login");
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}/product/single/${id}`);
      if (res?.status === 200) {
        setProduct(res?.data?.data);
      }
    } catch (error) {
    } finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleIncrement = () => setQuantity(quantity + 1);
  const handleDecrement = () => setQuantity(quantity > 1 ? quantity - 1 : 1);

  if (loading) {
    return (
      <div className="loader-div">
       <img src={load} alt="Logo" class="loader-logo"/>
       <div class="spinner"></div>
      </div>
    );
  }

  return (
    <>
      <div className="SingleProductDetail">
        <div className="SingleProductDetail-left">
          <div className="main-img">
            <img src={mainImage} alt="Main" />
          </div>
          <div className="side-img">
            {product?.images?.image1 && (
              <div>
                <div
                  className="side-box"
                  onClick={() => setMainImage(product.images.image1)}
                >
                  <img
                    src={product?.images?.image1 || img2}
                    style={{ width: "100%", height: "100%" }}
                    alt="Image 1"
                  />
                </div>
              </div>
            )}
            {product?.images?.image2 && (
              <div>
                <div
                  className="side-box"
                  onClick={() => setMainImage(product.images.image2)}
                >
                  <img
                    src={product?.images?.image2 || img2}
                    style={{ width: "100%", height: "100%" }}
                    alt="Image 2"
                  />
                </div>
              </div>
            )}
            {product?.images?.image3 && (
              <div>
                <div
                  className="side-box"
                  onClick={() => setMainImage(product.images.image3)}
                >
                  <img
                    src={product?.images?.image3 || img2}
                    style={{ width: "100%", height: "100%" }}
                    alt="Image 3"
                  />
                </div>
              </div>
            )}
            {product?.images?.image4 && (
              <div>
                <div
                  className="side-box"
                  onClick={() => setMainImage(product.images.image4)}
                >
                  <img
                    src={product?.images?.image4 || img2}
                    style={{ width: "100%", height: "100%" }}
                    alt="Image 4"
                  />
                </div>
              </div>
            )}
            {product?.images?.image5 && (
              <div>
                <div
                  className="side-box"
                  onClick={() => setMainImage(product.images.image5)}
                >
                  <img
                    src={product?.images?.image5 || img2}
                    style={{ width: "100%", height: "100%" }}
                    alt="Image 5"
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="SingleProductDetail-right">
          <h1>{product?.name}</h1>
          <h2>Rs {formatPrice(product?.price)}.00/month</h2>
          <p>
            {product?.description}
            {/* Nunc vehicula quam semper odio varius tincidunt. Vestibulum ante
            ipsum primis in faucibus orci luctus et ultrices posuere. */}
          </p>
          <br />

          <div className="select-slot">
            <p>Slot</p>
            <div className="slot-div">
              <label
                className={`radio-container ${
                  slot === "Morning" ? "checked" : ""
                }`}
              >
                <input
                  type="radio"
                  value="Morning"
                  checked={slot === "Morning"}
                  onChange={() => setSlot("Morning")}
                />
                <span className="checkmark"></span>
                Morning
                <p style={{ fontSize: "10px" }}>7 AM to 9 AM</p>
              </label>
              <label
                className={`radio-container ${
                  slot === "Evening" ? "checked" : ""
                }`}
              >
                <input
                  type="radio"
                  value="Evening"
                  checked={slot === "Evening"}
                  onChange={() => setSlot("Evening")}
                />
                <span className="checkmark"></span>
                Evening
                <p style={{ fontSize: "10px" }}>3 PM to 5 PM</p>
              </label>
            </div>
          </div>
          <br />

          <div className="select-slot">
            <p>Packaging Type</p>
            <div className="slot-div">
              <label
                className={`slotlabel ${
                  packageType === "onetimeuse" ? "checked" : ""
                }`}
              >
                <input
                  type="radio"
                  value="onetimeuse"
                  checked={packageType === "onetimeuse"}
                  onChange={() => setPackageType("onetimeuse")}
                />
                <span className="checkmark"></span>
                Plastic Bottle
              </label>
              <button className="glassbottel">
                Glass Bottle<span>Coming Soon</span>
              </button>
            </div>
          </div>

          <br />
          <div className="select-slot">
            <p>Start Date</p>
            {/* <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              style={{ height: "30px" }}
              className="datepiker"
              dateFormat="dd/MM/yyyy"
            /> */}
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="Select Start Date"
              filterDate={filterPassedDates}
              dateFormat="dd/MM/yyyy"
              style={{ height: "30px" }}
              className={`datepicker ${
                startDate ? "datepicker-selected" : "datepicker-unselected"
              }`}
            />
          </div>
          <br />
          <br />

          <div className="sing-pdt-btns">
            <button className="addtocart" onClick={handleAddtoCart}>
              ADD TO CART
            </button>
            <button className="buynow" onClick={handleBuyNow}>
              BUY NOW
            </button>
          </div>
          <br />
          <br />
          <button className="addtocart buytrial-btn" onClick={handleSubscribe}>
            BUY TRIAL RS. {formatPrice(product?.trialPack)}
          </button>
          <br />
          <br />
        </div>
      </div>
    </>
  );
}

export default SingleProductDetail;
