import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import maps from "../Assets/map.svg";
import arrow from "../Assets/arrow_back_ios.png";
import img1 from "../Assets/image 206.png";
import close from "../Assets/close.svg";
import { baseUrl } from "../utils/data";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import load from "../Assets/newlogo.png";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  DialogContentText,
  MenuItem,
  Select,
} from "@mui/material";
import { FaPencilAlt, FaPlus, FaTimes } from "react-icons/fa";
import Footer from "../Components/Footer";
import AddressDialog from "../Components/AddressDialog";
import { useCart } from "../cartContext/cartContext";
import SmallScreenLoader from "../loader/SmallScreenLoader";
import DatePicker from "react-datepicker";

function CheckOut() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [slot, setSlot] = useState("");
  const [packageType, setpackageType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [packageAmount, setPackageAmount] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  const [addresses, setAddresses] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState(null);
  const [productAmount, setProductAmount] = useState(0);
  const [packingAmount, setPackingAmount] = useState(0);
  const [load1, setLoad1] = useState(false);
  const [allCoupon, setAllCoupon] = useState([]);
  const [coupon, setCounpon] = useState("");
  const [discount, setDiscount] = useState(0);

  const [openIndex, setOpenIndex] = useState(null);

  const { setCartLength } = useCart();

  const getAllCoupon = async () => {
    try {
      const res = await axios.get(`${baseUrl}/coupon`);
      if (res.status == 200) {
        setAllCoupon(res?.data?.data);
      }
    } catch (error) {}
  };

  const handleApplyCoupon = () => {
    if (!coupon) {
      toast.error("Please Enter Coupon Code");
    }
    const matchingCoupon = allCoupon.find((c) => c.code === coupon);
    if (matchingCoupon) {
      const discountPercent = matchingCoupon.discount;
      const discountAmount = (Number(total) * discountPercent) / 100;
      const updatedTotal = Number(total) - discountAmount;

      // Format the updated total to two decimal places
      setDiscount(discountAmount);
      setTotal(updatedTotal.toFixed(2));
      toast.success(`Coupon applied! You get a ${discountPercent}% discount.`);
    } else {
      toast.error("Invalid Coupon Code");
    }
  };

  const filterPassedDates = (date) => {
    const day = date.getDay();
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return day !== 0 && date > today;
  };

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const fetchAddresses = async () => {
    try {
      const response = await axios.get(`${baseUrl}/user/bytoken`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setAddresses(response?.data?.data?.deliveryAddress);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  const handleSelectAddress = async (addressId) => {
    try {
      // console.log(addresses);
      // console.log(addressId);
      const selectedAddress = addresses.find(
        (address) => address._id === addressId
      );
      // console.log(selectedAddress);
      if (currentItemIndex !== null) {
        const updatedCartItems = [...cartItems];
        updatedCartItems[
          currentItemIndex
        ].deliveryAddress = `${selectedAddress.address}, ${selectedAddress.pincode}`;
        updatedCartItems[currentItemIndex].deliveryLocation =
          selectedAddress.location;
        // setCartItems(updatedCartItems);

        const payload = {
          deliveryAddress: updatedCartItems[currentItemIndex].deliveryAddress,
          deliveryLocation: updatedCartItems[currentItemIndex].deliveryLocation,
        };

        // Make the API call to update the item
        await axios.put(
          `${baseUrl}/newcart/updateitem/${updatedCartItems[currentItemIndex]._id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        toast.success("Cart item address updated successfully.");
        fetchCartData();
      }
    } catch (error) {
      console.error("Error updating cart item address:", error);
      toast.error("Failed to update cart item address");
    }
    setOpenDialog(false);
  };

  const handleCheckout = async () => {
    const userData = JSON.parse(localStorage.getItem("userData")) || null;
    if (userData) {
      const { name, mobile } = userData;
      if (!name || !mobile) {
        toast.error("Please Update Your Profile First.");
        navigate("/Profile");
      } else {
        if (
          cartItems.some(
            (item) => !item.deliveryAddress || !item.deliveryLocation
          )
        ) {
          toast.error(
            "Please select delivery addresses for all items in your cart."
          );
          return;
        }
        if (total <= 0) {
          return toast.error("Amount is Greater than Zero");
        }
        let payload = {
          subscriptions: cartItems,
        };
        if (discount > 0) {
          payload.couponCode = coupon;
        }
        setLoad1(true);
        try {
          const res = await axios.post(
            `${baseUrl}/subscription/newcreate`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (res?.status == 200) {
            const orderId = res?.data?.data[0]?.batchId;
            console.log(res);
            const result = await axios.post(
              `${baseUrl}/phonepe/phoneperequest`,
              {
                amount: total,
                merchantUserId: orderId,
              }
            );
            if (result?.status == 200) {
              window.location.href = result?.data;
            }
            // toast.success("Subscription Buy Successfully.");
            // navigate("/");
          }
        } catch (error) {
          toast.error("Buy Subscription Failed.");
        }
        setLoad1(false);

        // navigate("/Shipping");
      }
    } else {
      toast.error("Please Login First.");
      navigate("/Login");
    }
  };

  const fetchCartData = async () => {
    setLoad1(true);
    try {
      const response = await axios.get(`${baseUrl}/newcart/getcartbyuser`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setCartItems(response?.data?.data?.items);
      setCartLength(response?.data?.data?.items?.length);
    } catch (error) {
      console.error("Error fetching cart data:", error);
      if (error?.response?.data?.message === "User not authorized") {
        toast.error("Please login again.");
        navigate("/Login");
      }
    } finally {
      setLoading(false); // Set loading to false once data is fetched
    }
    setLoad1(false);
  };

  const handleClickOpen = (item) => {
    setCurrentItem(item);
    setSlot(item.slot);
    setpackageType(item.packageType);
    setStartDate(item.startDate);
    setPackageAmount(item?.product?.packageCharge);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = async () => {
    try {
      const payload = {
        slot,
        packageType,
        startDate,
        packingAmount: 0,
      };
      if (packageType == "onetimeuse") {
        payload.packingAmount = packageAmount;
      }
      setLoad1(true);
      await axios.put(
        `${baseUrl}/newcart/updateitem/${currentItem._id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchCartData();
      toast.success("Item updated successfully.");
      handleClose();
    } catch (error) {
      console.error("Error updating item:", error);
      if (error?.response?.data?.message === "User not authorized") {
        toast.error("Please login again.");
        navigate("/login");
      }
    }
    setLoad1(false);
  };

  const addSimilarProduct = async (productId) => {
    setLoad1(true);
    try {
      await axios.put(
        `${baseUrl}/newcart/addsimilartocart`,
        { productId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Similar product added to cart.");
      fetchCartData();
    } catch (error) {
      console.error("Error adding similar product:", error);
      if (error?.response?.data?.message === "User not authorized") {
        toast.error("Please login again.");
        navigate("/login");
      }
    }
    setLoad1(false);
  };

  const deleteProduct = async (productId) => {
    setLoad1(true);
    try {
      await axios.delete(`${baseUrl}/newcart/removeformcart/${productId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      toast.success("Product removed from cart.");
      fetchCartData();
    } catch (error) {
      console.error("Error deleting product:", error);
      if (error?.response?.data?.message === "User not authorized") {
        toast.error("Please login again.");
        navigate("/login");
      }
    }
    setLoad1(false);
  };

  const handleTotal = () => {
    const totalAmount = cartItems.reduce(
      (acc, cur) => acc + cur?.orderAmount + cur?.packingAmount,
      0
    );
    const totalPackingAmount = cartItems.reduce(
      (acc, cur) => acc + cur?.packingAmount,
      0
    );
    const totalProductAmount = cartItems.reduce(
      (acc, cur) => acc + cur?.orderAmount,
      0
    );
    setTotal(totalAmount);
    setPackingAmount(totalPackingAmount);
    setProductAmount(totalProductAmount);
  };

  useEffect(() => {
    handleTotal();
  }, [cartItems]);

  useEffect(() => {
    fetchCartData();
    fetchAddresses();
    getAllCoupon();
  }, []);

  if (loading) {
    return (
      <div className="loader-div">
        <br />
        <br />
        <img src={load} alt="Logo" class="loader-logo" />
        <div class="spinner"></div>
        <br />
        <br />
      </div>
    );
  }
  return (
    <>
      <Navbar />
      {load1 && <SmallScreenLoader />}

      <br />
      <br />

      <h1 className="check-heading" style={{ textAlign: "center" }}>
        Checkout
      </h1>
      <div className="checkout-div">
        {/* <div className="checkout-div-left">
          <div className="checkout-contact">
            <p>Contact</p>
            <h4>Have an account?</h4>
          </div>
          <div className="name-inps">
            <input placeholder="First Name" />
            <input placeholder="Last Name" />
          </div>
          <input placeholder="Email" />
          <br />
          <input placeholder="Phone" />
          <br />
          <br />
          <img className="map" src={maps} alt="Map" />
          <br />
          <br />
          <h3>Shipping Address</h3>
          <input placeholder="Country/region" />
          <br />
          <input placeholder="Company(Optional)" />
          <br />
          <input placeholder="Address" />
          <br />
          <input placeholder="Apartment, suite, etc. (optional)" />
          <br />
          <div className="name-inps">
            <input placeholder="Postal Code" />
            <input placeholder="City" />
          </div>
          <br />

          <div className="continue-btn">
            <button className="Return" onClick={() => navigate("/cart")}>
              <img src={arrow} alt="Return" /> Return to cart
            </button>

            <button className="CONTINUESHIPPING">CONTINUE TO SHIPPING</button>
          </div>
        </div> */}
        <div
          className="checkout-div-right"
          style={{
            width: "100%",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <h2>Your Cart</h2>
          <br />

          <div className="new-cart-cards">
            {cartItems?.length > 0 &&
              cartItems?.map((item, index) => (
                <div
                  key={item?.product?._id}
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <div className="new-cart-card">
                    <img
                      src={item?.product?.imageUrl || img1}
                      alt={item?.product?.name}
                    />
                    <br />
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "rgb(228, 228, 228)",
                      }}
                    ></div>
                    <br />

                    <h2>{item?.product?.name}</h2>

                    <div className="card-p">
                      <p>Slot: {item?.slot}</p>
                      {/* <p>
                        Packaging Type:{" "}
                        {item?.packageType == "onetimeuse"
                          ? "Plastic Bottle"
                          : "Glass Bottle"}
                      </p> */}
                      <p>
                        Start Date:{" "}
                        {new Date(item?.startDate)?.toLocaleDateString()}
                      </p>
                      <p>Price : {item?.orderAmount}</p>
                      {/* <p>Package Charge : {item?.packingAmount}</p> */}
                      <Button
                        onClick={() => {
                          setOpenDialog(true);
                          setCurrentItemIndex(index);
                        }}
                        className="newaddselect"
                      >
                        {item.deliveryAddress
                          ? "Change Address"
                          : "Select Address"}
                      </Button>
                      {item.deliveryAddress && <p>{item.deliveryAddress}</p>}
                    </div>
                    <div className="cart-ip">
                      {/* <div className="cart-ip" > */}
                      <div className="cart-ip-border">
                        <FaPencilAlt
                          alt="Edit"
                          onClick={() => handleClickOpen(item)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div className="cart-ip-border">
                        <FaPlus
                          alt="Add Similar"
                          onClick={() => addSimilarProduct(item.product._id)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div className="cart-ip-border">
                        <FaTimes
                          alt="Remove"
                          onClick={() => deleteProduct(item._id)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>

                      {/* </div> */}
                    </div>
                  </div>

                  <div
                    className="cart-item-text"
                    style={{ display: "flex", gap: "20px" }}
                  ></div>
                  <br />
                  <br />
                </div>
              ))}
          </div>
          {/* <br />
          <br />
          <br />
          <br />
          <br />
          <br /> */}

          {/* <div className="cart-total">
            <p>Subtotal ({cartItems?.length || 0})</p>
            <h3>Rs {total}.00</h3>
          </div> */}
          <br />

          {/* <div className="cart-total">
            <p>Tax</p>
            <h3>Rs 00.00</h3>
          </div> */}
          <br />

          {/* <div className="cart-total">
            <p>Shipping</p>
            <h3>Free</h3>
          </div> */}
          <br />

          {/* <div className="cart-total">
            <p>Total Orders:</p>
            <h3>Rs {total}.00</h3>
          </div> */}
        </div>
      </div>
      <br />

      {/* <div className="continue-btn" style={{ justifyContent: "center" }}>
        <button className="Return" onClick={() => navigate("/cart")}>
          <img src={arrow} alt="Return" /> Return to cart
        </button>

        <button className="CONTINUESHIPPING" onClick={handleCheckout}>
          PROCEED
        </button>
      </div> */}

      <div className="accordion">
        <div className="accordion-item">
          <div className="cart-total">
            <input
              type="text"
              placeholder="Enter Coupon Code"
              value={coupon}
              onChange={(e) => setCounpon(e.target.value)}
            />
            <button className="CONTINUESHIPPING" onClick={handleApplyCoupon}>
              Apply Coupon
            </button>
          </div>
          <br />
          <br />
        </div>
      </div>

      <div className="accordion">
        <div className="accordion-item">
          <div className="accordion-title" onClick={() => handleClick(0)}>
            <h3>Subtotal Rs {Number(total).toFixed(2)}</h3>
            <div className="plus">{openIndex === 0 ? "-" : "+"}</div>
          </div>
          <br />
          <br />
          <br />
          {openIndex === 0 && (
            <>
              <div className="cart-total">
                <p>Product Amount ({cartItems?.length || 0})</p>
                <h3>Rs {productAmount}.00</h3>
              </div>
              <br />

              <div className="cart-total">
                <p>Tax</p>
                <h3>Rs 00.00</h3>
              </div>
              <br />

              <div className="cart-total">
                <p>Shipping</p>
                <h3>Free</h3>
              </div>
              <br />
              <div className="cart-total">
                <p>Discount</p>
                <h3>Rs {Number(discount).toFixed(2)}</h3>
              </div>
              <br />

              <div className="cart-total">
                <p>Packing</p>
                <h3>Rs {packingAmount}.00</h3>
              </div>
              <br />

              <div className="cart-total">
                <p>Total :</p>
                <h3>Rs {Number(total).toFixed(2)}</h3>
              </div>
              <br />
              <br />
            </>
          )}
        </div>
        <div className="continue-btn" style={{ justifyContent: "center" }}>
          {/* <button className="Return" onClick={() => navigate("/cart")}>
          <img src={arrow} alt="Return" /> Return to cart
        </button> */}

          <button className="CONTINUESHIPPING" onClick={handleCheckout}>
            PROCEED
          </button>
          <br />
          <br />
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Item</DialogTitle>
        <DialogContent>
          <DialogContentText>Update the details of the item.</DialogContentText>
          <Select
            autoFocus
            margin="dense"
            label="Slot"
            value={slot}
            onChange={(e) => setSlot(e.target.value)}
            fullWidth
          >
            {/* <MenuItem value="">Select</MenuItem> */}
            <MenuItem value="Morning">Morning</MenuItem>
            {/* <MenuItem value="Afternoon">Afternoon</MenuItem> */}
            <MenuItem value="Evening">Evening</MenuItem>
          </Select>
          <Select
            margin="dense"
            label="Packaging Type"
            value={packageType}
            onChange={(e) => setpackageType(e.target.value)}
            fullWidth
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            {/* <MenuItem value="resualbe">Glass Bottle</MenuItem> */}
            {/* <MenuItem value="">Select</MenuItem> */}
            <MenuItem value="onetimeuse">Plastic Bottle</MenuItem>
          </Select>
          {/* <TextField
            margin="dense"
            label="Start Date"
            type="date"
            fullWidth
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          /> */}
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            placeholderText="Select Start Date"
            filterDate={filterPassedDates}
            dateFormat="dd/MM/yyyy"
            style={{ height: "30px" }}
            className="datepiker"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdate} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <AddressDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleSelectAddress={handleSelectAddress}
        fetchAddresses={fetchAddresses}
        addresses={addresses}
      />
    </>
  );
}

export default CheckOut;
