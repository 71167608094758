import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Components/Navbar";
import Products from "../Components/Products";
import pinapple from "../Assets/pianapple.svg";
import product1 from "../Assets/image 173.svg";
import { baseUrl } from "../utils/data";
import axios from "axios";
import ProductsComponent from "./Product/ProductsComponent";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../Components/Footer";
import OurProduct from "./HomePage/OurProduct";
import load from '../Assets/newlogo.png'
const products = [
  { id: 1, name: "The Lemonade Love", price: 200, image: product1 },
  { id: 2, name: "The Lemonade Love", price: 200, image: product1 },
  { id: 3, name: "The Lemonade Love", price: 200, image: product1 },
  { id: 4, name: "The Lemonade Love", price: 200, image: product1 },
  { id: 5, name: "The Lemonade Love", price: 200, image: product1 },
  { id: 6, name: "The Lemonade Love", price: 200, image: product1 },

  // Add more products as needed
];
const products2 = [
  { id: 1, name: "The Lemonade Love", price: 200, image: product1 },
  { id: 2, name: "The Lemonade Love", price: 200, image: product1 },
  { id: 3, name: "The Lemonade Love", price: 200, image: product1 },
  { id: 4, name: "The Lemonade Love", price: 200, image: product1 },
  { id: 5, name: "The Lemonade Love", price: 200, image: product1 },
  { id: 6, name: "The Lemonade Love", price: 200, image: product1 },

  // Add more products as needed
];
function NewProduct() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true); // Add loading state

  const [allProduct, setAllProduct] = useState(null);
  const getAllProduct = async () => {
    try {
      const res = await axios.get(`${baseUrl}/product/subproduct/all`);
      setAllProduct(res?.data?.data);
    } catch (error) {}
    // finally {
    //   setLoading(false); 
    // }
 
  };
  useEffect(() => {
    getAllProduct();
  }, []);
  console.log(allProduct);
  const containerRef = useRef(null);
  const containerRef2 = useRef(null);

  const scroll = (direction) => {
    const { current } = containerRef;
    if (direction === "left") {
      current.scrollBy({ left: -220, behavior: "smooth" });
    } else {
      current.scrollBy({ left: 220, behavior: "smooth" });
    }
  };

  const scroll2 = (direction) => {
    const { current } = containerRef2;
    if (direction === "left") {
      current.scrollBy({ left: -220, behavior: "smooth" });
    } else {
      current.scrollBy({ left: 220, behavior: "smooth" });
    }
  };

  const handleAddtoCart = async (productId) => {
    try {
      const res = await axios.post(
        `${baseUrl}/cart`,
        { productId, quantity: 1 },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res?.status == 200) {
        toast.success("Product Added in Cart.");
      }
    } catch (error) {
      if (error?.response?.data?.message == "User not authorized") {
        toast.error("Please Login Again.");
        navigate("/login");
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
    
  };
  // if (loading) {
  //   return (
  //     <div className="loader-div">
  //     <br/>
  //     <br/>
  //     <img src={load} alt="Logo" class="loader-logo"/>
  //     <div class="spinner"></div>
  //     <br/>
  //     <br/>

  //     </div>
  //   );
  // }
  return (
    <>
      <>
        <Navbar />
        <div className='aboutbanner aboutbanner2'>
    <div className='aboutbannercolor'>
    <h1>OUR PRODUCTS</h1>
<p>Enjoy the convenience of having fresh, delicious juices delivered right to your door at your chosen time.<br/> Experience the pure, powerful, and perfect way to nourish your body every day. Subscribe today for a happier you.</p>
    </div>

   

    </div>

        <div className="products-div" style={{backgroundColor:'white'}}>
      <br/>
      <br/>
      <br/>
          
          {/* <p>Featured Product </p> */}
          {/* <h1>Healthy living was done with fruit juice.</h1> */}
      
          {/* <div className="newproducts">
            <div className="newproducts-cards">
              <div className="newproducts-card">
                <img src={pinapple} />
                <br />

                <p>Pineapple Juice</p>
                <br />
                <h4>Rs 200.00</h4>
                <br />
                <button>ADD TO CART</button>
              </div>
              <br />
              <div className="newproducts-card">
                <img src={pinapple} />
                <br />

                <p>Pineapple Juice</p>
                <br />
                <h4>Rs 200.00</h4>
                <br />
                <button>ADD TO CART</button>
              </div>
              <br />
              <div className="newproducts-card">
                <img src={pinapple} />
                <br />

                <p>Pineapple Juice</p>
                <br />
                <h4>Rs 200.00</h4>
                <br />
                <button>ADD TO CART</button>
              </div>
            </div>
            <div className="new-product-right new-product-right-10">
              {allProduct &&
                allProduct.length > 0 &&
                allProduct?.map((e) => (
                  <div className="product-carousel product-carousel-10" style={{marginBottom:"10px"}} >
                    <div className="newproducts-card newproducts-card-10"  >
                      <img src={ e?.imageUrl || pinapple}  style={{width:"200px"}} />
                      <br />

                      <p>{e?.name}</p>
                    
                      <h4>Rs. {e?.price}/month</h4>
                   
                      <button onClick={()=>navigate(`/ProductDetails/${e?._id}`)}>Subscribe</button>
                    </div>
                   <ProductsComponent products ={e?.SubProducts} />
                  </div>
                ))}

              <br />
              <div className="product-carousel">
                <div className="newproducts-card">
                  <img src={pinapple} />
                  <br />

                  <p>Pineapple Juice</p>
                  <br />
                  <h4>Rs 200.00</h4>
                  <br />
                  <button>ADD TO CART</button>
                </div>
                <button className="nav-button" onClick={() => scroll2("left")}>
                  ‹
                </button>
                <div className="product-container" ref={containerRef2}>
                  {products.map((product2) => (
                    <div className="product-card" key={product2.id}>
                      <img src={product2.image} alt={product2.name} />
                      <p>{product2.name}</p>
                      <h4>Rs {product2.price}.00</h4>
                      <button>ADD TO CART</button>
                    </div>
                  ))}
                </div>
                <button className="nav-button" onClick={() => scroll2("right")}>
                  ›
                </button>
              </div>
            </div>
          </div> */}
      {/* <OurProduct/> */}

        </div>
      </>
      <OurProduct/>
      <br/>
      <br/>
      <Footer />
    </>
  );
}

export default NewProduct;
